<template>
  <div class="uiSwitchLanguage">
    <select v-model="$i18n.locale" @change="onChange($event)">
      <option
        v-for="(name, lang) in langs"
        :key="`lang-${lang}`"
        :value="lang">
          {{ lang }}
      </option>
    </select>
  </div>
</template>
<script>
export default {
  name: 'SwitchLocale',
  data() {

    let language = localStorage.getItem('language');

    if(language && language!==this.$i18n.locale){
      this.$i18n.locale = language;
    }

    return { langs: {'ar':'عربية', 'en':'English', 'pt':'Portugeese', 'ro':'Romania'} }
  },
  methods:{
    onChange: function (event){

       localStorage.language = event.target.value;
       this.$root.$emit("languageSwitched", 'ciao');

    }
  }
}
</script>
<style>
select {
    width: 100%;
    height: 20px;
    font-size: 10px;
    outline: 0;
    text-transform: uppercase;
    margin: 20px auto !important;
    margin-right: 20px;
}
select option { font-size: 14px; }


</style>
