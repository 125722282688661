import { render, staticRenderFns } from "./PageNotifications.vue?vue&type=template&id=950300d8&scoped=true&"
import script from "./PageNotifications.vue?vue&type=script&lang=js&"
export * from "./PageNotifications.vue?vue&type=script&lang=js&"
import style0 from "./PageNotifications.vue?vue&type=style&index=0&id=950300d8&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "950300d8",
  null
  
)

export default component.exports