<template>
  <div class="uiSwitchLanguage">
    <select v-model="$i18n.locale" @change="onChange($event)">
      <option
        v-for="(name, lang) in langs"
        :key="`lang-${lang}`"
        :value="lang">
          {{ name }}
      </option>
    </select>
  </div>
</template>
<script>
export default {
  name: 'SwitchLocale',
  data() {

    let language = localStorage.getItem('language');

    if(language && language!==this.$i18n.locale){
      this.$i18n.locale = language;
    }

    return { langs: {'ar':'عربية', 'en':'English', 'pt':'Portugeese', 'ro':'Romania'} }
  },
  methods:{
    onChange:function(event){
       console.log(event.target.value);
       localStorage.language = event.target.value;
       this.$root.$emit("languageSwitched", 'ciao');
       this.$language('post');
    }
  }
}
</script>
<style>
select {
    width: 100%;
    line-height: 49px;
    height: 38px;
    font-size: 14px;
    outline: 0;
    margin: 20px auto !important;
}
select option { font-size: 14px; }


</style>
