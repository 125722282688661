import dayjs from "dayjs";
var localizedFormat = require("dayjs/plugin/localizedFormat");
dayjs.extend(localizedFormat);

export default class Payout {

    static STATUS_PENDING = 0;
    static STATUS_COMPLETE = 1;

    id = 0;
    amount = 0;
    status = Payout.STATUS_PENDING;
    info = {};
    created_at = dayjs();
    updated_at = dayjs();

    constructor(data) {
        this.id = data.id;
        this.status = data.status;
        this.created_at = dayjs(data.created_at);
        this.updated_at = dayjs(data.updated_at);
        this.info = data.info;
        this.amount = data.amount;
    }

}