export default class PayoutMethod {

    static TYPE_PAYPAL = 0;
    static TYPE_BANK = 1;

    id = 0;
    type = PayoutMethod.TYPE_PAYPAL;
    info = {};

    constructor(data) {
        this.id = data.id;
        this.type = data.type;
        this.info = data.info;
    }

}